import { css } from '@emotion/react';
import React from 'react';

const container = css({ width: '100%', height: '100vh' });

export const Contact = () => (
  <div id="Contact" css={container}>
    Contact
  </div>
);
