export const ParticleConfig = {
  fullScreen: { enable: true, zIndex: 1 },
  fpsLimit: 60,
  interactivity: {
    detectsOn: 'canvas',
    events: { onHover: { enable: true, mode: 'grab', parallax: { enable: true, force: 20 } } },
    modes: { grab: { distance: 150 } },
  },
  particles: {
    color: { value: '#ffffff' },
    links: { color: '#ffffff', enable: false, opacity: 0.1, width: 0.1, distance: 500 },
    collisions: { enable: true },
    move: {
      direction: 'none',
      enable: true,
      outMode: 'bounce',
      random: true,
      speed: 1,
      straight: true,
    },
    number: { density: { enable: true, value_area: 800 }, value: 40 },
    opacity: { value: 1 },
    shape: { type: 'circle' },
    size: { random: false, value: 0.4 },
  },
  detectRetina: true,
};
