import { css, Global } from '@emotion/react';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Particles from 'react-tsparticles';
import { Intro } from '../components/intro';
import { Primary, Secondary } from '../config/constants';
import { ParticleConfig } from '../config/particle-config';
import { Skills } from '../components/skills';
import { Projects } from '../components/projects';
import { Contact } from '../components/contact';

import '@fontsource/ubuntu/400.css';
import '@fontsource/ubuntu/500.css';
import '@fontsource/ubuntu/700.css';

const global = css({
  ['html, body']: {
    cursor: `url(cursor.png), default`,
    width: '100%',
    padding: 0,
    margin: 0,
    overflowX: 'hidden',
    background: Primary,
    fontFamily: 'Ubuntu',
    '&::-webkit-scrollbar': { width: '.4vw', height: '.4vw' },
    '&::-webkit-scrollbar-thumb': { background: Secondary, borderRadius: '.3vw' },
    '&::-webkit-scrollbar-track': { background: Primary, borderRadius: '.3vw', margin: '1vw .5vw .5vw 1vw' },
    '&::-webkit-scrollbar-corner': { backgroundColor: 'inherit' },
  },
  '*': { boxSizing: 'border-box', scrollBehavior: 'smooth' },
});

const IndexPage = () => (
  <>
    <Helmet title="Caciano Almeida" />
    <Global styles={global} />
    <Intro />
    <Skills />
    <Projects />
    <Contact />
    <Particles width="100%" height="100%" options={ParticleConfig} />
  </>
);

export default IndexPage;
