// Different sections of webpage
export const States = {
  Home: 'Home',
  Skills: 'Skills',
  Projects: 'Projects',
  Contact: 'Contact',
};

// Color scheme
export const Primary = '#1a1a1a';
export const Secondary = '#cc1c1c';

// media query for desktop size
export const DesktopSize = '@media (min-width: 1000px)';
